<template>
    <div
         v-loading="loading"
        element-loading-text="数据加载中"
        element-loading-background="rgba(255, 255, 255, 1)" 
        class="artificialIntelligence"
    >
        <iframe 
            ref="ifra"
            allowTransparency="true"
            scrolling="no" 
            :src="src">
        </iframe>
    </div>
</template>
<script>
    import { getToken } from "@/utils/auth";
export default {
    name:'artificialIntelligence',
    data(){
        return{
            src:null,
            loading:true,
        }
    },
    created(){
        this.src = `https://iscoring.creditscoring.cn/iscoring/?token=${getToken()}`
    },
    mounted() {
        const frame = this.$refs.ifra
        frame.addEventListener('load',()=>{
            this.loading = false
        })
    },
}
</script>
<style lang="scss" scoped>
    .artificialIntelligence{
        width: 100%;
        height: auto;
        iframe{
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // width: 980px;
            width: 100%;
            // transform: translate(-50%,-50%);
            min-height: 1020px;
            height: calc(100vh - 80px - 110px);
            overflow: hidden;
            // zoom: 0.8;
            border: none!important;
            margin: 0;
            padding: 0;
        }
    }
</style>